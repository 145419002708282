import { useEffect, useState } from "react";
import NewCaseDescription from "../component/NewCaseDescription";
import { addNewCase, agreementCreate, allIncidents, uploadDocuments } from "../api/user/user.service";
import { useNavigate } from "react-router-dom";
import Loader from "../component/Loader";
import { getCookie } from "../utils/cookie/cookie";
import DatatableForIncidents from "../component/DatatableForIncidents";



const My_incidents = () => {

  const [descriptionForm, setDescriptionForm] = useState(false);
  var navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [incidents, setIncidents] = useState([]);
  const authorization = getCookie('authorization');
  const [formState, setFormState] = useState('');
  const [searchIncident, setSearchIncident] = useState(null)
  const [searchInquiries, setSearchInquiries] = useState(null);
  const [formType, setFormType] = useState("")






  const handleSearch = (event) => {
    setSearchIncident(event.target.value)
    inquiries = incidents.filter((data) => {
      console.log(event.target.value)
      if (event.target.value !== null) {
        return data._id
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      }

      return data;
    });
  };
  const handleAnswer = (event) => {
    setFormState(event.target.value)
  };

  let inquiries = incidents.filter((data) => {
    console.log(searchInquiries)
    console.log(formState)
    console.log(data)
    if (searchInquiries !== null) {
      return data._id
        .toLowerCase()
        .includes(searchIncident.toLowerCase());
    }

    return data;
  });

  if (formState == 'active') {
    inquiries.sort((a, b) => {
      if (a.formState < b.formState) return -1;
      if (a.formState > b.formState) return 1;
      return 0;
    });
  } else if (formState == 'pause') {
    inquiries.sort((a, b) => {
      if (a.formState > b.formState) return -1;
      if (a.formState < b.formState) return 1;
      return 0;
    });
  } else if (formState == 'triage') {
    inquiries.sort((a, b) => {
      if (a.formState > b.formState) return -1;
      if (a.formState < b.formState) return 1;
      return 0;
    });
  } else if (formState == 'escalated') {
    inquiries.sort((a, b) => {
      if (a.formState > b.formState) return -1;
      if (a.formState < b.formState) return 1;
      return 0;
    });
  }


  const popupDescriptionForm = (type) => {
    setFormType(type)
    setDescriptionForm(true);
  }
  useEffect(() => {
    console.log("get all users")
    setIsLoading(true)
    allIncidents()
      .then((response) => {
        console.log(response);
        setIncidents(response.data)
        setIsLoading(false)
      }).catch((error) => {
        console.log(error.response);
        console.log(error.response.status);
        if (error.response.status == 401) {
          return navigate('/login')
        }
        return error.response
      });
  }, []);

  const popupDescriptionClose = () => setDescriptionForm(false);

  const padding = {
    padding: '20px',
  };
  const marginBottom = {
    marginBottom: '0px',
  };
  const row = {
    margin: '0px',
  };

  // upload file
  const fileUpload = (files) => {
    console.log("fileuploadf")
    // uploadDocuments(files).then((response) => {
    //   console.log(response.data);
    // });
  }

  // save main form full json object in database
  const getDescriptionData = (data) => {
    try {
      setIsLoading(true);
      // event.preventDefault();
      console.log("props data")
      console.log(data)

      addNewCase(data)
        .then(function (response) {
          console.log("Then");
          console.log(response)
          // response = response.data;
          setIsLoading(false);
          if (response.data.incidentType == 'full') {
            navigate("/fullcase/" + response.data._id);
          } else {
            navigate("/quickcase/" + response.data._id);
          }
        })
        .catch(function (error) {
          console.log("catch");
          setIsLoading(false);
          if (error.response) {
            // Request made and server responded
            setErrorMessage(error.response.data.message);
            console.log(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    } catch (e) {
      console.log(e);
      setErrorMessage("Server error");
      setIsLoading(false);
    }
    setDescriptionForm(false);
  }
  const clickhandler = name => console.log("delete", name);

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="sm:flex sm:items-center px-4 sm:px-6 lg:px-8 mt-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            My Cases
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the cases in your account.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => popupDescriptionForm("full")}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-[1rem] font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Full Case
          </button>
          <button
            type="button"
            onClick={() => popupDescriptionForm("quick")}
            className="ml-6 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-[1rem] font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Quick Case
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className='row border-radius-10 default-shadow' style={row}>
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <DatatableForIncidents data={incidents} click={clickhandler} handleFile={fileUpload} />
            </div>
          </div>
        </div>
      </div>
      {descriptionForm ? (
        <>
          <div
            id="overlay"
            className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
          />
          <div
            id="dialogForPerson"
            className="overflow-x-hidden overflow-y-auto flex-1 fixed inset-0 flex items-center w-fit min-w-[50%] min-h-fit mx-auto top-1/4 z-50 bg-white rounded-md p-4 md:px-8 md:py-6 space-y-5 drop-shadow-lg"
          >
            <NewCaseDescription formType={formType} onSubmit={getDescriptionData} popupClose={popupDescriptionClose} />

          </div>
        </>
      ) : (
        ""
      )}

    </>
  )
}

export default My_incidents