import React, { useEffect, useState } from "react";
import Loader from "../component/Loader";
import { DateUtils } from "../utils/date/date";
import { allAllocatedIncidents, getAllUsers, sendEmail, updateIncident } from "../api/user/user.service";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";


function Allocate_incident() {
  const [people, setUserList] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [filterText, setFilterText] = React.useState("");
  const [filterTextDropdown, setFilterTextDropdown] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  // const filteredItems = props.data.filter(
  //   item =>
  //     JSON.stringify(item)
  //       .toLowerCase()
  //       .indexOf(filterText.toLowerCase()) !== -1
  // );

  const columns = [
    {
      name: "Reference",
      selector: "_id",
      sortable: true,
      cell: row =>
        <>
          <div className="flex items-center">
            <div className="ml-2">
              <div className="font-medium text-gray-900">
                {row._id}
              </div>
            </div>
          </div>
        </>
    },
    {
      name: "Incident Date",
      selector: "createdAt",
      sortable: true,
      hide: "sm",
      cell: row =>
        <>
          <div className="text-gray-900">
            {DateUtils.toDateString(row.createdAt)}
          </div>
        </>,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      grow: 2
    },
    {
      name: "Allocated",
      selector: "user",
      cell: (row) => {
        if (row.user) {
          return <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">Yes</span>
        } else {
          return <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-red-400">No</span>
        }
      }
      ,
      sortable: true,
      hide: "md"
    },
    {
      name: "Practitioner",
      selector: "user",
      sortable: true,
      cell: (incident) => {
       return <select
          onChange={(e) => {
            handleAllocatedIncident(incident._id, e.target.value,incident.createdAt,incident.description)
          }}
          value={incident.user == null ? '' : incident.user}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.95rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >

          <option value='' />
          {people.length > 0 ? people.map((e, key) => {
            return (
              <option id={e._id} value={e._id}>
                {e.first_name} {e.last_name}
              </option>
            );
          }) : ''}
        </select>
      }
    },

  ];



  const handleAllocatedIncident = (incidentId, userId,date,description) => {
    // console.log(e.target.selectedIndex)
    // console.log(e.target.childNodes[e.target.selectedIndex].getAttribute('id'))
    setIsLoading(true)
    updateIncident({ _id: incidentId, user: userId ? userId : null })
      .then((response) => {
        console.log(response);
        // setUserList(response.data);
        Swal.fire({
          icon: 'success',
          title: 'User has been allocated',
          showConfirmButton: false,
          timer: 1000
        })
        allIncidents();
        console.log(people)
      });

      let objForEmail = {
        case_id: incidentId,
        practitioner_name: getAdminUserName(userId),
        incident_date: date,
        description: description,
        email: getAdminUserEmail(userId)
      }
      
      console.log(objForEmail);
      sendEmail('email-j', objForEmail).then((response) => {
        console.log(response);
      });


  }

  // get user name 

  const getAdminUserName = (id) => {
    console.log(people);
    for (let i = 0; i < people.length; i++) {
      console.log(people[i]._id);
      console.log(id);
      if (people[i]._id == id) {
        return `${people[i].first_name} ${people[i].last_name}`
      }
    }
  }

  // get user email

  const getAdminUserEmail = (id) => {
    for (let i = 0; i < people.length; i++) {
      if (people[i]._id == id) {
        return people[i].email
      }
    }
  }

  const allIncidents = () => {
    allAllocatedIncidents()
      .then((response) => {
        console.log(response);
        setIncidents(response.data);
        setIsLoading(false)
        console.log(incidents)
      });
  }

  const getUsers = () => {
    getAllUsers()
      .then((response) => {
        console.log(response);
        setUserList(response.data);
        console.log(people)
      });
  }
  useEffect(() => {
    console.log("get all users")
    setIsLoading(true)
    getUsers();
    allIncidents();
  }, []);
  return (
    <>
      {isLoading ? <Loader /> : ""}

      <div className="px-4 sm:px-6 lg:px-8 mt-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Allocate Case
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the cases.
            </p>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {/* <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                      >
                        Reference
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                      >
                        Incident Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                      >
                        Allocated
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[1rem] font-semibold text-gray-900"
                      >
                        Practitioner
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {incidents.length > 0 ? incidents.map((incident) => (
                      <tr key={incident._id}>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-[1rem] sm:pl-6">
                          <div className="ml-2">
                            <div className="font-medium text-gray-900">
                              {incident._id}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-[1rem] text-gray-500">
                          <div className="text-gray-900">{DateUtils.toDateString(incident.createdAt)}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-[1rem] ">
                          {incident.description}
                        </td>

                        <td className="whitespace-nowrap px-3 py-2 text-[1rem] text-gray-500">
                          {incident.user != null ? <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-green-800">Yes</span> : <span className="inline-flex rounded-full bg-green-100 px-2 text-sm font-semibold leading-5 text-red-400">No</span>}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-[1rem] text-gray-500">
                          <select
                            onChange={(e) => {
                              handleAllocatedIncident(incident._id, e.target.value)
                            }}
                            value={incident.user == null ? '' : incident.user}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-[0.95rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          >

                            <option value='' />
                            {people.length > 0 ? people.map((e, key) => {
                              return (
                                <option id={e._id} value={e._id}>
                                  {e.first_name} {e.last_name}
                                </option>
                              );
                            }) : ''}
                          </select>
                        </td>
                      </tr>
                    )) : ''}

                  </tbody>
                </table> */}
                <DataTable
                  title="Contact List"
                  columns={columns}
                  data={incidents}
                  defaultSortFieldId = {2}
                  defaultSortAsc={false}
                  striped
                  pagination
                  subHeader
                // subHeaderComponent={subHeaderComponent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Allocate_incident;
