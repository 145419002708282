import {
  getCookie,
  removeCookie,
  setCookie,
} from '../../utils/cookie/cookie';
import { DateUtils } from '../../utils/date/date';

class AuthService {
  login(tokenId) {
    setCookie('authorization', tokenId, DateUtils.getFutureDate(1));
    return true;
  }

  logout() {
    removeCookie('authorization');
  }

  getCurrentUser() {
    const token = getCookie('authorization');
    return token;
  }
}
export default new AuthService();