import { useNavigate } from "react-router-dom";
import auth from "../api/auth/auth.service";
import React, { useEffect, useState } from "react";
import { loginReq } from "../api/user/user.service";
import Loader from "../component/Loader";
import Error from "../component/Error";
import ForgotPass from "../component/ForgotPass";
import logo from "../logos/logo.png";
import logoLeft from "../logos/watermarkLeft.png";
import logoRight from "../logos/watermarkRight.png";

const Login = () => {
  var navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [login, setlogin] = useState(false);

  const [open, setOpen] = useState(false);
  function update(b) {
    setOpen(b);
  }

  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (!authenticated) {
      const authorization = auth.getCurrentUser();
      if (authorization) {
        setAuthenticated(true);
        navigate("/dashboard");
      }
    }
  }, [authenticated]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      var data = new FormData(event.target);
      console.log(data);
      let formObject = Object.fromEntries(data.entries());
      console.log(formObject);
      formObject.email = formObject.email.toLowerCase();
      console.log(formObject)
      loginReq(JSON.stringify(formObject))
        .then(function (response) {
          console.log("Then");

          response = response.data;
          console.log(response.token);
          auth.login(response.token);
          setIsLoading(false);
          navigate("/");
        })
        .catch(function (error) {
          console.log("catch");
          setIsLoading(false);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
            setErrorMessage(error.response.data.message);
            setIsLoading(false);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            setErrorMessage(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error);
            setErrorMessage(error);

          }
        });
    } catch (e) {
      console.log(e);
      setErrorMessage("Server error");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      {open && (
        <ForgotPass
          onClose={() => {
            update(false);
          }}
        />
      )}

      <div className='relative flex flex-col justify-center min-h-screen overflow-hidden' >
        {/* <img className="h-full w-100% m-auto absolute left-0 top-0 -z-10" src={logoLeft} alt="Logo" />
        <img className="h-full w-100% m-auto absolute right-0 top-0 -z-10 hidden md:block" src={logoRight} alt="Logo" /> */}

        <div className='w-full m-auto z-10 p-2'>
          <div className="bg-white border-t-8 m-auto border-black rounded shadow-lg shadow-black-800/50 max-w-[500px]">
            {/* <div className="w-full">
              <img className="h-full w-full" src={logo} alt="Logo" />
            </div> */}
            <div className="m-auto px-6 lg:max-w-md">
              <img className="h-36 w-100% m-auto" src={logo} alt="Logo" />
            </div>
            <div className="w-full bg-gradient-to-r from-[#b5cd1f] to-[#009a46] text-white font-semibold text-center">
              <span>Endeavour Resolution Service</span>
            </div>
            <div className="py-4 px-6 md:px-12">

              {errorMessage && <Error text={errorMessage} />}
              <form onSubmit={handleSubmit} method="POST" className="mt-6">
                <div>
                  <label htmlFor="email" className="block text-[1.1rem] font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    required
                    type="email"
                    id="email"
                    name="email"
                    className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-f-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div className="mt-4">
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-[1.1rem] font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <input
                      required
                      type="password"
                      id="password"
                      name="password"
                      className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-green-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                  <a
                    href="#"
                    onClick={() => {
                      update(true);
                    }}
                    className="text-xs text-gray-600 hover:underline"
                  >
                    Forgot Password?
                  </a>
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gradient-to-r from-[#b5cd1f] to-[#009a46] rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
