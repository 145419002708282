import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "../component/Loader";
import logo from "../logos/logoNavbar.png";
import { useNavigate } from "react-router-dom";
import { feedbackForm } from "../api/user/user.service.js";
import Swal from "sweetalert2";

const ParticipantFeedback = (props) => {
    var navigate = useNavigate();
    const { _caseId, _participantId } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    console.log(_caseId);
    console.log(_participantId);

    const intialFeedbackFormData = Object.freeze({ caseId: _caseId, participantId: _participantId, partInRestoration: '', conflictResolution: '', expectParticipating: '', meetingWithFacilitatior: '', whatHappened: '', feeling: '', wasAffected: '', resolveSituation: '', reachSatisfactoryConclusion: '', overallSatisfied: '' })
    const [feedbackFormRes, updateFeedbackForm] = React.useState(intialFeedbackFormData);

    const handleChangeFeedbackForm = (e) => {
        updateFeedbackForm({
            ...feedbackFormRes,
            [e.target.name]: e.target.value
        });
    }

    const submit = () => {
        console.log("submit")
        console.log(feedbackFormRes);
        setIsLoading(true)

        feedbackForm(feedbackFormRes).then((res) => {
            console.log(res)
            Swal.fire({
                text: 'Your Feedback has been submitted successfully. '
              })
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
    }, []);

    return (
        <>
            {isLoading ? <Loader /> : ""}

            <div className="font-medium justify-center items-center flex text-gray-900 bg-black w-full">
                <img className="h-14 w-100% 3xl:h-24 m-auto" src={logo} alt="Logo" />
            </div>

            <div
                className="flex mt-6 md:mt-14 lg:mt-16 xl:mt-20 3xl:mt-28 justify-center items-center mx-2 md:mx-4"
                style={{ backgroundColor: "#ffffff" }}
            >
                <div
                    className="flex-1 md:max-w-6xl xl:max-w-[80rem] 3xl:max-w-[80%] bg-white min-h-full mx-auto p-2 md:p-4 shadow-md rounded-lg"
                    style={{ backgroundColor: "#F8F8F8" }}
                >

                    <div id="mainCon" className="p-3 md:p-4 xl:p-12 3xl:p-[5%] rounded mt-2 md:mt-3">
                        <div className="justify-center mt-3 mb-4 md:mb-7">
                            <p className="text-xl md:text-2xl font-bold text-center">
                                Participant feedback
                            </p>
                            <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                            {/* <div className="grid grid-cols-12 gap-4 md:gap-12 p-2 md:p-4"> */}
                            <table style={{
                                borderCollapse: 'separate',
                                borderSpacing: '0px 1rem',
                                "width": "100%"
                            }}>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Did you volunteer to take part in the restorative process?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%]">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="partInRestoration"
                                                id="yes_partInRestoration"
                                                defaultValue="Yes"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="yes_partInRestoration"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%]">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="partInRestoration"
                                                id="no_partInRestoration"
                                                value="No"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="no_partInRestoration"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Had you heard of conflict resolution before using the service?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%]">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="conflictResolution"
                                                id="yes_conflictResolution"
                                                defaultValue="Yes"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="yes_conflictResolution"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%]">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="conflictResolution"
                                                id="no_conflictResolution"
                                                value="No"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="no_conflictResolution"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            How well did the facilitator explain to you what to expect from participating in the conflict resolution process?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%]">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="expectParticipating"
                                                id="very_expectParticipating"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="very_expectParticipating"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Very well
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%]">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="expectParticipating"
                                                id="fair_expectParticipating"
                                                value="Fair"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="fair_expectParticipating"
                                                className="radio text-center self-center h-full py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Fair / Satisfactory
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%]">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="expectParticipating"
                                                id="not_expectParticipating"
                                                value="Not well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="not_expectParticipating"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Not well
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Did you have a one on one meeting with the facilitator?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%]">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingWithFacilitatior"
                                                id="yes_meetingWithFacilitatior"
                                                defaultValue="Yes"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="yes_meetingWithFacilitatior"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%]">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingWithFacilitatior"
                                                id="no_meetingWithFacilitatior"
                                                value="No"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="no_meetingWithFacilitatior"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                            <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                            <p className="text-[1.1rem] font-semibold text-gray-600">
                                During the interviews, rate how well you think the Facilitator dealt with each of the following sections:
                            </p>
                            <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                            <table style={{
                                borderCollapse: 'separate',
                                borderSpacing: '0px 1rem',
                                "width": "100%"
                            }}>
                                <tr className="w-full">
                                    <td className="w-[50%]"></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600 "> Very well</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600">Well</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600">Fair / Satisfactory</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600"> Poor</span></td>
                                    <td className="w-[10%] text-center"><span className="text-[1rem] my-2 font-semibold text-gray-600">Very Poor</span></td>

                                </tr>
                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            What happened
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="whatHappened"
                                                id="5_whatHappened"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_whatHappened"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                5
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="whatHappened"
                                                id="4_whatHappened"
                                                value="Well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_whatHappened"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                4
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="whatHappened"
                                                id="3_whatHappened"
                                                value="Fair / Satisfactory"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_whatHappened"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                3
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="whatHappened"
                                                id="2_whatHappened"
                                                value="Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_whatHappened"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                2
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="whatHappened"
                                                id="1_whatHappened"
                                                value="Very Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_whatHappened"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                1
                                            </label>
                                        </div>
                                    </td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Feelings and emotions
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feeling"
                                                id="5_feeling"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_feeling"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                5
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feeling"
                                                id="4_feeling"
                                                value="Well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_feeling"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                4
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feeling"
                                                id="3_feeling"
                                                value="Fair / Satisfactory"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_feeling"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                3
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feeling"
                                                id="2_feeling"
                                                value="Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_feeling"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                2
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feeling"
                                                id="1_feeling"
                                                value="Very Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_feeling"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                1
                                            </label>
                                        </div>
                                    </td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Who else was affected
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="wasAffected"
                                                id="5_wasAffected"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_wasAffected"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                5
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="wasAffected"
                                                id="4_wasAffected"
                                                value="Well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_wasAffected"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                4
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="wasAffected"
                                                id="3_wasAffected"
                                                value="Fair / Satisfactory"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_wasAffected"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                3
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="wasAffected"
                                                id="2_wasAffected"
                                                value="Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_wasAffected"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                2
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="wasAffected"
                                                id="1_wasAffected"
                                                value="Very Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_wasAffected"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                1
                                            </label>
                                        </div>
                                    </td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            What solutions could resolve the situation
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="resolveSituation"
                                                id="5_resolveSituation"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_resolveSituation"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                5
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="resolveSituation"
                                                id="4_resolveSituation"
                                                value="Well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_resolveSituation"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                4
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="resolveSituation"
                                                id="3_resolveSituation"
                                                value="Fair / Satisfactory"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_resolveSituation"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                3
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="resolveSituation"
                                                id="2_resolveSituation"
                                                value="Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_resolveSituation"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                2
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="resolveSituation"
                                                id="1_resolveSituation"
                                                value="Very Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_resolveSituation"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                1
                                            </label>
                                        </div>
                                    </td>
                                </tr>


                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            What you needed in order to move on
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="orderToMove"
                                                id="5_orderToMove"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_orderToMove"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                5
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="orderToMove"
                                                id="4_orderToMove"
                                                value="Well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_orderToMove"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                4
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="orderToMove"
                                                id="3_orderToMove"
                                                value="Fair / Satisfactory"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_orderToMove"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                3
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="orderToMove"
                                                id="2_orderToMove"
                                                value="Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_orderToMove"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                2
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="orderToMove"
                                                id="1_orderToMove"
                                                value="Very Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_orderToMove"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                1
                                            </label>
                                        </div>
                                    </td>
                                </tr>

                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            How well informed did you feel about meeting with the other party?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingOtherParty"
                                                id="5_meetingOtherParty"
                                                value="Very well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_meetingOtherParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                5
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingOtherParty"
                                                id="4_meetingOtherParty"
                                                value="Well"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_meetingOtherParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                4
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingOtherParty"
                                                id="3_meetingOtherParty"
                                                value="Fair / Satisfactory"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_meetingOtherParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                3
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingOtherParty"
                                                id="2_meetingOtherParty"
                                                value="Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_meetingOtherParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                2
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="meetingOtherParty"
                                                id="1_meetingOtherParty"
                                                value="Very Poor"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_meetingOtherParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                1
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            How safe did you feel meeting with the other party?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="safeMeetingParty"
                                                id="5_safeMeetingParty"
                                                value="Very safe"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_safeMeetingParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Very safe
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="safeMeetingParty"
                                                id="4_safeMeetingParty"
                                                value="Safe"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_safeMeetingParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Safe
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="safeMeetingParty"
                                                id="3_safeMeetingParty"
                                                value="Fairly Safe"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_safeMeetingParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Fairly Safe
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="safeMeetingParty"
                                                id="2_safeMeetingParty"
                                                value="Not Safe"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_safeMeetingParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Not Safe
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="safeMeetingParty"
                                                id="1_safeMeetingParty"
                                                value="Very Unsafe"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_safeMeetingParty"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Very Unsafe
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Do you feel safe now with the other people involved?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feelSafeNowWithOther"
                                                id="yes_feelSafeNowWithOther"
                                                defaultValue="Yes"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="yes_feelSafeNowWithOther"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="feelSafeNowWithOther"
                                                id="no_feelSafeNowWithOther"
                                                value="No"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="no_feelSafeNowWithOther"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[70%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Did you reach a satisfactory conclusion?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="reachSatisfactoryConclusion"
                                                id="yes_reachSatisfactoryConclusion"
                                                defaultValue="Yes"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="yes_reachSatisfactoryConclusion"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Yes
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className=" bg-black text-white inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="reachSatisfactoryConclusion"
                                                id="no_reachSatisfactoryConclusion"
                                                value="No"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="no_reachSatisfactoryConclusion"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                No
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="w-full">
                                    <td className="w-[50%]">
                                        <label
                                            className="text-[1.1rem] my-2 font-semibold text-gray-600"
                                        >
                                            Overall are you satisfied with how the Facilitator managed the process?
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                    </td>
                                    {/* <div className="float-right"> */}
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="overallSatisfied"
                                                id="5_overallSatisfied"
                                                value="Very satisfied"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="5_overallSatisfied"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Very satisfied
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="overallSatisfied"
                                                id="4_overallSatisfied"
                                                value="Satisfied"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="4_overallSatisfied"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Satisfied
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="overallSatisfied"
                                                id="3_overallSatisfied"
                                                value="Fairly Satisfied"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="3_overallSatisfied"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Fairly Satisfied
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="overallSatisfied"
                                                id="2_overallSatisfied"
                                                value="Not Satisfied"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="2_overallSatisfied"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Not Satisfied
                                            </label>
                                        </div>
                                    </td>
                                    <td className="w-[10%] text-center">
                                        <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                            <input
                                                type="radio"
                                                name="overallSatisfied"
                                                id="1_overallSatisfied"
                                                value="Very Unsatisfied"
                                                onChange={handleChangeFeedbackForm}
                                                hidden
                                            />
                                            <label
                                                htmlFor="1_overallSatisfied"
                                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                            >
                                                Very Unsatisfied
                                            </label>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </div>

                        {/* </div> */}
                        <div className="col-span-full text-center mt-4">
                            <button
                                type="button"
                                onClick={submit}
                                id="nextBtn"
                                className="inline-flex items-center px-6 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md text-white button focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                                <span>Submit </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ParticipantFeedback;
