import React, { useState } from "react";
import { DateUtils } from '../../utils/date/date'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect } from "react";
import { getEmotionsAfter, getEmotionsBefore, getEmotionsDuring, getSolutions } from "../../api/user/user.service";
import Loader from "../../component/Loader";
import html2canvas from "html2canvas";
import jsPDF from "jspdf"
import Swal from "sweetalert2";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: '',
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                precision: 0
            }
        }
    }
};

export default function Intelligence() {

    const intialIncidentsData = Object.freeze({ reportType: "", startOfIncident: "", endOfIncident: "" })
    const [incidentsData, updateIncidentsData] = React.useState(intialIncidentsData);
    const [startDefaultDate, setStartDefaultDate] = useState();
    const [endDefaultDate, setEndDefaultDate] = useState();
    const [mainDiv, setMainDiv] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [chartTitle, setChartTitle] = useState();
    const [chartAllData, setchartAllData] = useState({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                backgroundColor: '#D87574',
            },
        ],
    })

    const handleChangeIncidentsData = (e) => {
        updateIncidentsData({
            ...incidentsData,
            [e.target.name]: e.target.value
        });
        if (e.target.name == 'startOfIncident') {
            setStartDefaultDate(DateUtils.reduceMonths(new Date(e.target.value), 0));
        } else if (e.target.name == 'endOfIncident') {
            setEndDefaultDate(DateUtils.reduceMonths(new Date(e.target.value), 0));
        }
    }
    // console.log(labels.map(() => faker.datatype.number({ min: 0, max: 1000 })));


    const changeDates = (e) => {
        if (e.target.value) {

            console.log(DateUtils.reduceMonths(new Date(), e.target.value));
            setStartDefaultDate(DateUtils.reduceMonths(new Date(), e.target.value));
            setEndDefaultDate(DateUtils.reduceMonths(new Date(), 0));
            updateIncidentsData({
                ...incidentsData,
                ["startOfIncident"]: DateUtils.reduceMonths(new Date(), e.target.value),
                ["endOfIncident"]: DateUtils.reduceMonths(new Date(), 0),
            });
        }
    }

    const reportGenerate = () => {

        var startDate = new Date(incidentsData.startOfIncident);
        var endDate = new Date(incidentsData.endOfIncident);

        if (incidentsData.reportType == '') return Swal.fire({
            text: 'Please Select Report Type',
        })

        if (incidentsData.startOfIncident == '') return Swal.fire({
            text: 'Please Enter Date or Select Date Range',
        })

        if (incidentsData.endOfIncident == '') return Swal.fire({
            text: 'Please Enter Date or Select Date Range',
        })

        setMainDiv(false)
        setChartTitle(incidentsData.reportType)
        setIsLoading(true)



        let data = [];
        let labels = [];

        if (incidentsData.reportType == 'Emotions Experienced Before Incident') {
            getEmotionsBefore(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    // setIncidents(response.data);
                    console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i]._id) {
                            labels.push(response.data[i]._id)
                            data.push(response.data[i].count)
                        }
                    }

                    setchartAllData({
                        labels,
                        datasets: [
                            {
                                label: 'Emotions',
                                data: data,
                                backgroundColor: '#D87574',
                            },
                        ],
                    });

                    setIsLoading(false)
                });
        } else if (incidentsData.reportType == 'Emotions Experienced During Incident') {
            getEmotionsDuring(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    // setIncidents(response.data);
                    console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i]._id) {

                            labels.push(response.data[i]._id)
                            data.push(response.data[i].count)
                        }
                    }

                    setchartAllData({
                        labels,
                        datasets: [
                            {
                                label: 'Emotions',
                                data: data,
                                backgroundColor: '#D87574',
                            },
                        ],
                    });

                    setIsLoading(false)
                });
        } else if (incidentsData.reportType == 'Emotions Experienced After Incident') {
            getEmotionsAfter(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    // setIncidents(response.data);
                    console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i]._id) {
                            labels.push(response.data[i]._id)
                            data.push(response.data[i].count)
                        }
                    }
   

                    setchartAllData({
                        labels,
                        datasets: [
                            {
                                label: 'Emotions',
                                data: data,
                                backgroundColor: '#D87574',
                            },
                        ],
                    });

                    setIsLoading(false)
                });
        } else if (incidentsData.reportType == 'Solutions') {
            console.log("Solutions")
            getSolutions(startDate, endDate)
                .then((response) => {
                    console.log(response);
                    // setIncidents(response.data);
                    console.log(response.data);
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i]._id) {
                            labels.push(response.data[i]._id)
                            data.push(response.data[i].count)
                        }
                    }
   

                    setchartAllData({
                        labels,
                        datasets: [
                            {
                                label: 'Solutions',
                                data: data,
                                backgroundColor: '#D87574',
                            },
                        ],
                    });

                    setIsLoading(false)
                });
        }
    }

    const mainDivPage = () => {
        setMainDiv(true);
        setchartAllData({
            labels: [],
            datasets: [
                {
                    label: '',
                    data: [],
                    backgroundColor: '#D87574',
                },
            ],
        });
    }

    const div2PDF = (e) => {

        const but = e.target;
        but.style.visibility = "hidden";

        let input = document.getElementsByClassName("div2PDF")[0];

        html2canvas(input).then(canvas => {
            let w, h;
            const img = canvas.toDataURL("image/png", w = canvas.width, h = canvas.height);
            const pdf = new jsPDF("p", "mm", "a4");
            var width = pdf.internal.pageSize.getWidth() - 20;
            pdf.addImage(
                img,
                "png",
                10,
                10,
                width,
                width * (h / w)
            );
            pdf.save("chart.pdf");
            but.style.visibility = "visible";

        }).catch(error => console.log(error));
    };

    useEffect(() => {
        console.log("get all users")
        // setIsLoading(true)

    }, []);

    return (<>
        {isLoading ? <Loader /> : ""}

        {mainDiv ? (<>
            <div className="mx-8 mt-12 md:w-[60%]">
                <div className="mb-8">
                    <p className="text-2xl text-gray-800 font-semibold">Generate</p>
                </div>
                <div className="w-full my-4">
                    <label
                        htmlFor="reportType"
                        className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                    >
                        Report Type
                    </label>
                    <div className="mt-2 relative rounded-md shadow-sm">

                        <select
                            onChange={handleChangeIncidentsData}
                            // value={rowsDataClassificationTemp.name}
                            name='reportType'
                            className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-[1rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                            <option value="" disabled selected='true'>Please select one of the following Reports below</option>
                            <option value="Emotions Experienced Before Incident">Emotions Experienced Before Incident</option>
                            <option value="Emotions Experienced During Incident">Emotions Experienced During Incident</option>
                            <option value="Emotions Experienced After Incident">Emotions Experienced After Incident</option>
                            <option value="Solutions">Solutions</option>
                        </select>
                    </div>
                </div>
                <div className="w-full my-4">
                    <label
                        htmlFor="reportType"
                        className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                    >
                        Date Range
                    </label>
                    <div className="mt-2 relative rounded-md shadow-sm text-center md:max-w-[60%] ">
                        <div className="w-full md:w-1/2 inline-block">
                            <label
                                htmlFor="startDate"
                                className="text-[1.1rem] 3xl:text-[1.5rem] mx-6 3xl:my-4 font-semibold text-gray-600"
                            >
                                Start Date
                            </label>
                            <input
                                value={startDefaultDate}
                                onChange={handleChangeIncidentsData}
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 w-max focus:border-green-500 p-2.5"
                                id="startOfIncident"
                                name="startOfIncident"
                            />
                        </div>
                        <div className="w-full md:w-1/2 inline-block">
                            <label
                                htmlFor="reportType"
                                className="text-[1.1rem] 3xl:text-[1.5rem] mx-6 3xl:my-4 font-semibold text-gray-600"
                            >
                                End Date
                            </label>
                            <input
                                value={endDefaultDate}
                                onChange={handleChangeIncidentsData}
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 w-max focus:border-green-500 p-2.5"
                                id="endOfIncident"
                                name="endOfIncident"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full my-8">
                    <div className="border-b-2 col-span-full w-[45%] my-1 inline-block" />
                    <div className="col-span-full w-[10%] text-center inline-block justify-center"><p className="m-auto my-auto"> OR</p></div>
                    <div className="border-b-2 col-span-full w-[45%] my-1 inline-block" />
                </div>
                <div className="w-full my-8">
                    <label
                        className="text-[1.1rem] my-4 font-semibold text-gray-600"
                    >
                        Quick Search
                    </label>
                    <div className="float-right inline-block ml-6">
                        <div className="bg-white border border-black inline-flex mx-4 rounded-xl">
                            <input
                                type="radio"
                                name="quickSearch"
                                id="6_quickSearch"
                                value="6"
                                onChange={changeDates}
                                // checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                            />
                            <label
                                htmlFor="6_quickSearch"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                6 Months
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-xl">
                            <input
                                type="radio"
                                name="quickSearch"
                                id="12_quickSearch"
                                value="12"
                                onChange={changeDates}
                                // checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                            />
                            <label
                                htmlFor="12_quickSearch"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                12 Months
                            </label>
                        </div>
                        <div className="bg-white border border-black inline-flex mx-4 rounded-xl">
                            <input
                                type="radio"
                                name="quickSearch"
                                id="24_quickSearch"
                                value="24"
                                onChange={changeDates}
                                // checked={mainFormData.particioantsEscalate === `Yes`}
                                hidden
                            />
                            <label
                                htmlFor="24_quickSearch"
                                className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                            >
                                24 Months
                            </label>
                        </div>

                    </div>

                </div>
                <div>
                    <button
                        onClick={reportGenerate}
                        type="button"
                        className="inline-flex max-h-12 bottom-0 my-8 items-center px-6 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md bg-green-600 hover:bg-green-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                        <span> Generate </span>
                    </button>
                </div>
            </div>
        </>
        ) : (
            <>
                <div className="mx-8 mt-12 w-full">
                    <button
                        id="prevBtn"
                        type="button"
                        onClick={mainDivPage}
                        className="previousBtn inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent text-[1.1rem] font-semibold rounded-md shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 previousBtn"
                    >
                        <span>Back </span>
                    </button>
                    <div className="div2PDF">
                        <div className="w-full mt-4">
                            <span className="text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600 mx-4">{chartTitle ? chartTitle : ''}</span>
                            <button className="bg-green-500 inline-flex mr-3 md:mr-4 items-center px-3 py-2 border border-transparent font-semibold rounded-full shadow-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" onClick={e => div2PDF(e)}>Export to PDF</button>
                        </div>

                        <div className="w-full border border-solid m-2 p-4">

                            <Bar options={options} data={chartAllData} />
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
    )
}