import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { useState } from "react";
import Loader from "./Loader";
import { getCookie } from "../utils/cookie/cookie";
import { API_URL } from "../api/baseurl";
import Swal from "sweetalert2";

export default function ForgotPass(props) {
  const [isLoading, setIsLoading] = useState(false);

  function passWord(userInfo) {
    try {
      const authorization = getCookie("authorization");
      axios({
        method: "post",
        url: API_URL + `/auth/forgot-password`,
        data: JSON.stringify(userInfo),
        headers: {
          "Content-Type": "application/json",
          authorization: authorization,
        },
      })
        .then((response) => {
          console.log("Then");
          setIsLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Request for Password reset successful. Please check your email",
            icon: "success",
          });
          props.onClose()

          return response.data;
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error");

          console.log(error);
          console.log(error.response.data.message);
          if (error.response.data.message) {
            console.log(error.response.data.message);
            Swal.fire({
              title: "Something went wrong!",
              text: "error.response.data.message",
              icon: "error",
            });
          }
        });
    } catch (e) {
      setIsLoading(false);
      console.log("e");
      console.log(e);
      alert(e);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    var data = new FormData(event.target);
    let formObject = Object.fromEntries(data.entries());
    console.log(formObject);
    formObject.email = formObject.email.toLowerCase();
    console.log(formObject)

    const response = await passWord(formObject);

    console.log("response");
    console.log(response);
    // setIsLoading(false);
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            props.onClose();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                  <form onSubmit={handleSubmit} method="POST">
                    <div>
                      <div className="mt-3 sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg text-center leading-6 font-bold text-gray-800"
                        >
                          Please enter email address to reset password
                        </Dialog.Title>
                        <div className="border-b-2 col-span-full my-3 md:my-6" />

                        <div className="overflow-hidden sm:rounded-md">
                          <div className="px-4 py-5 bg-white sm:p-6">
                            {/* <div className='grid grid-cols-6 gap-4'> */}
                            <div className="col-span-6 sm:col-span-4">
                              <label
                                htmlFor="email"
                                className="block text-base text-gray-800"
                              >
                                Email
                              </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                  required
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-base"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                    {/* </div> */}
                    <div className="mt-5 sm:mt-6 text-right">
                      <button
                        type="button"
                        className="inline-flex justify-center m-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                        onClick={() => props.onClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="Submit"
                        className="inline-flex justify-center m-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
