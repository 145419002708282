const toDateString = (ISOString) => {
  return new Date(ISOString).toDateString();
  // return new Date(ISOString);
};

const reduceMonths = (date, months) => {
  date.setMonth(date.getMonth() - (+months));
  let dateMDY = date.toISOString().split('T')[0]
  return dateMDY;
}

const getFutureDate = (dayToAdd) => {
  const date = new Date();
  date.setDate(date.getDate() + dayToAdd);
  return date;
};

const getFutureDateAddHrs = (hrToAdd) => {
  const date = new Date();
  date.setHours(date.getHours() + hrToAdd);
  return date;
};

const getDayDiffFromNow = (dateStr) => {
  const currnt_date = new Date();
  const date = new Date(dateStr);
  const one_day = 1000 * 60 * 60 * 24;

  return Math.floor((currnt_date.getTime() - date.getTime()) / one_day);
};

function getTimeDiff(current, previous) {
  const current_time = current.getTime();
  const previous_time = previous.getTime();
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current_time - previous_time;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' days ago';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' months ago';
  } else {
    return Math.round(elapsed / msPerYear) + ' years ago';
  }
}
const DateUtils = {
  getDayDiffFromNow,
  getFutureDateAddHrs,
  getFutureDate,
  toDateString,
  getTimeDiff,
  reduceMonths
};
export { DateUtils };