import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { passwordSet } from "../api/user/user.service";
import Loader from "../component/Loader";
import Error from "../component/Error";
import qs from "qs";
import logo from "../logos/logo.png";
import logoLeft from "../logos/watermarkLeft.png";
import logoRight from "../logos/watermarkRight.png";
import { useNavigate } from "react-router-dom";

const Password_set = (props) => {
  var navigate = useNavigate();
  const [passwordError, setPasswordErr] = useState("");
  const [params, setParams] = useState({
    id: "",
    token: "",
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });

  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  function userPasswordSet(pass) {
    try {
      passwordSet(params.id, params.token, JSON.stringify(pass))
        .then(function (response) {
          console.log("Then");
          setIsLoading(false);
          console.log(response);
          if (response.data == "OK") {
            alert("Password added successfully");
            document.getElementById("password_set_form").reset();
            navigate("/login");

          }
          return response.data;
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error");

          console.log(error);
          console.log(error.response);
          if (error.response.data) {
            console.log(error.response.data);
            alert(error.response.data);
          }
        });
    } catch (e) {
      setIsLoading(false);
      console.log("e");
      console.log(e);
      alert(e);
    }
  }

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minimum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleSubmit = async (event) => {
    console.log("submit");
    try {
      event.preventDefault();
      setIsLoading(true);
      console.log(confirmPasswordError);
      console.log(passwordError);
      if (confirmPasswordError == "" && passwordError == "") {
        var data = new FormData(event.target);
        console.log(data);
        let formObject = Object.fromEntries(data.entries());
        console.log(formObject);
        userPasswordSet(formObject);
      } else {
        setIsLoading(false);
        alert("Please check errors");
        console.log(confirmPasswordError);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("Server error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const obj = qs.parse(location.search, { ignoreQueryPrefix: true });
    setParams(obj);
  }, [location]);

  return (
    <>
      {isLoading ? <Loader /> : ""}

      {/* {errorMessage && <div className="error">{errorMessage}</div>} */}
      <div className='relative flex flex-col justify-center min-h-screen overflow-hidden' >
        {/* <img className="h-full w-100% m-auto absolute left-0 top-0 -z-10" src={logoLeft} alt="Logo" />
        <img className="h-full w-100% m-auto absolute right-0 top-0 -z-10 hidden md:block" src={logoRight} alt="Logo" /> */}

        <div className='w-full m-auto z-10 p-2'>
          <div className="bg-white border-t-8 m-auto border-black rounded shadow-lg shadow-black-800/50 max-w-[500px]">
            {/* <div className="w-full">
              <img className="h-full w-full" src={logo} alt="Logo" />
            </div> */}
            <div className="m-auto p-6 lg:max-w-md">
              <img className="h-14 w-100% m-auto" src={logo} alt="Logo" />
            </div>
            <div className="w-full bg-black text-white font-semibold text-center">
              <span>Endeavour Resolution Service</span>
            </div>
            <div className="py-4 px-6 md:px-12">
              {errorMessage && <Error text={errorMessage} />}
              <form
                onSubmit={handleSubmit}
                id="password_set_form"
                method="POST"
                className="mt-6"
              >
                <div className="mt-4">
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-[1.1rem] text-gray-700"
                    >
                      Password
                    </label>
                    <input
                      onChange={handlePasswordChange}
                      onKeyUp={handleValidation}
                      required
                      type="password"
                      id="password"
                      name="password"
                      className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-green-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <p className="text-red-400 text-xs">{passwordError}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <div>
                    <label
                      htmlFor="conform_password"
                      className="block text-[1.1rem] text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <input
                      onChange={handlePasswordChange}
                      onKeyUp={handleValidation}
                      required
                      type="text"
                      id="confirmPassword"
                      name="confirmPassword"
                      className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-green-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <p className="text-red-400 text-xs">{confirmPasswordError}</p>
                  </div>
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-700 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
                    >
                      Set Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password_set;
