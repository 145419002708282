import React, { useState } from "react"


export default function Userform(props) {

    //   const intialSubFormData = Object.freeze({ areYouComfortable: '', whatHappened: '', beforeIncident: [], duringIncident: [], afterIncident: [], physicalLocation: '', digitalLocation: '', dateOfIncident: '', timeOfIncident: '', presentCharacteristics: [], drugs: '', solutions: [] })
    const intialFormData = Object.freeze({ firstName: '', lastName: '', yearGroup: '', site: '',gender:'', addedBy: '', status: '', id: '1', data: { areYouComfortable: '', whatHappened: '', beforeIncident: [], duringIncident: [], afterIncident: [], physicalLocation: '', digitalLocation: '', dateOfIncident: '', timeOfIncident: '', presentCharacteristics: [], Conflict: [], drugs: '', solutions: [] } })
    // const intialFormData = Object.freeze({ firstName: '', lastName: '', email: '', contact: '', typeOfWork: '', locationOfWork: '', addedBy: '', status: '', id: '1', data: { areYouComfortable: '', whatHappened: '', beforeIncident: [], duringIncident: [], afterIncident: [], physicalLocation: '', digitalLocation: '', dateOfIncident: '', timeOfIncident: '', presentCharacteristics: [], Conflict: [], drugs: '', solutions: [] } })
    const [userFormData, updateUserFormData] = React.useState(intialFormData);

    const handleChangeUserForm = (e) => {
        if (e.target.name == "firstName" || e.target.name == "lastName") {
            console.log("capitals")
            console.log(e.target.name)
            updateUserFormData({
                ...userFormData,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            });
        }else if (e.target.name == "email") {
            console.log(e.target.name)
            updateUserFormData({
                ...userFormData,
                [e.target.name]: e.target.value.toLowerCase()
            });
        } else {
            updateUserFormData({
                ...userFormData,
                [e.target.name]: e.target.value
            });
        }
    };

    // first letter capital
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }



    const popupUserFormClose = () => {
        props.popupClose();
    }

    const handleSubmitUserForm = (e) => {
        e.preventDefault()
        props.onSubmit(userFormData)
        console.log(userFormData);
    };

    return (
        <>
            <form onSubmit={handleSubmitUserForm}>
                <div className="mt-3 text-center">
                    <div className="grid grid-cols-12 gap-2 md:gap-5 my-2">
                        <div className="sm:col-span-12 md:col-span-4 col-span-full">
                            <label
                                htmlFor="firstName"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                First Name
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <input required onChange={handleChangeUserForm}
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={userFormData.firstName}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                />
                            </div>
                        </div>
                        <div className="md:col-span-4 col-span-full">
                            <label
                                htmlFor="lastName"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                Surname
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <input required onChange={handleChangeUserForm}
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    value={userFormData.lastName}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                />
                            </div>
                        </div>
                        {/* <div className="md:col-span-4 col-span-full">
                            <label
                                htmlFor="email"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                Email Address
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <input required onChange={handleChangeUserForm}
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={userFormData.email}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                />
                            </div>
                        </div> */}
                        {/* <div className="md:col-span-4 col-span-full">
                            <label
                                htmlFor="contact"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                Contact Number
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <input onChange={handleChangeUserForm}
                                    type="tel"
                                    name="contact"
                                    id="contact"
                                    value={userFormData.contact}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                />
                            </div>
                        </div> */}
                        <div className="md:col-span-4 col-span-full">
                            <label
                                htmlFor="yearGroup"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                Pupil / Staff
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <select onChange={handleChangeUserForm}
                                    name="yearGroup"
                                    id="yearGroup"
                                    value={userFormData.yearGroup}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                >
                                    <option value="" />
                                    <option value="Year 5">Year 5</option>
                                    <option value="Year 6">Year 6</option>
                                    <option value="Year 7">Year 7</option>
                                    <option value="Year 8">Year 8</option>
                                    <option value="Year 9">Year 9</option>
                                    <option value="Year 10">Year 10</option>
                                    <option value="Year 11">Year 11</option>
                                    <option value="Year 12">Year 12</option>
                                    <option value="Year 13">Year 13</option>
                                    <option value="Member of Staff">Member of Staff</option>
                                    <option value="External Person">External Person</option>

                                </select>
                            </div>
                        </div>
                        <div className="md:col-span-4 col-span-full">
                            <label
                                htmlFor="site"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                Site
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <select onChange={handleChangeUserForm}
                                    name="site"
                                    id="site"
                                    value={userFormData.site}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                >
                                    <option value="" />
                                    <option value="Southerncross">Southerncross</option>
                                    <option value="Meadehill">Meadehill</option>
                                    <option value="Castlefield Campus">Castlefield Campus</option>
                                </select>
                            </div>
                        </div>
                        <div className="md:col-span-4 col-span-full">
                            <label
                                htmlFor="gender"
                                className="block text-[1.1rem] font-semibold text-gray-600"
                            >
                                Gender
                            </label>
                            <div className="mt-2 relative rounded-md shadow-sm">
                                <select onChange={handleChangeUserForm}
                                    name="gender"
                                    id="gender"
                                    value={userFormData.gender}
                                    className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                                >
                                    <option value="" />
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Identify as male">Identify as male</option>
                                    <option value="Identify as female">Identify as female</option>
                                    <option value="Transgender">Transgender</option>
                                    <option value="Prefer not to say">Prefer not to say</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        id="addPersonDetails"
                        type="submit"
                        className="px-5 py-2 m-2 bg-green-600 hover:bg-green-800 text-white cursor-pointer rounded-md"
                    >
                        Add
                    </button>
                    <button
                        onClick={popupUserFormClose}
                        id="closePerson"
                        type="button"
                        className="px-5 py-2 m-2 bg-red-500 hover:bg-red-700 text-white cursor-pointer rounded-md"
                    >
                        Close
                    </button>
                </div>
            </form>
        </>
    )
};