import React from "react";
import auth from "../api/auth/auth.service";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const user = auth.getCurrentUser();
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = () => {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
