import React, { useEffect, useState } from "react";
import { getAllUsers } from "../api/user/user.service";

export default function EscalateCase(props) {

    const intialFormData = Object.freeze({ escalateTo: '', forSafety: '', noParticipants: '', assistProcess: '', additional: [] })
    const [escalateCaseData, updateEscalateCaseData] = React.useState(intialFormData);

    const [people, setUserList] = useState([]);

    const getUsers = () => {
        getAllUsers()
            .then((response) => {
                setUserList(response.data);
            });
    }


    const handleChangeEscalateForm = (e) => {
        console.log(e.target.value);
        updateEscalateCaseData({
            ...escalateCaseData,
            [e.target.name]: e.target.value
        })
        console.log(escalateCaseData)

    };

    const popupEscalateCaseClose = () => {
        props.popupClose();
    }

    const handleSubmitEscalateCase = (e) => {
        e.preventDefault()
        props.onSubmit(escalateCaseData)
        console.log(escalateCaseData);
    };

    // for additional person

    const [rowsDataAdditional, setRowsDataAdditional] = useState([]);
    const intialRowDataAdditional = Object.freeze({ firstName: '', lastName: '', email: '' })
    const [rowsDataAdditionalTemp, setRowsDataAdditionalTemp] = React.useState(intialRowDataAdditional);

    const handleChangeAdditionalDataforTable = (e) => {
        setRowsDataAdditionalTemp({
            ...rowsDataAdditionalTemp,
            [e.target.name]: e.target.value
        });
    }

    const addAdditionalTableRows = (e) => {
        e.preventDefault();
        if (rowsDataAdditionalTemp.firstName != '') {
            setRowsDataAdditional(rowsDataAdditional => [...rowsDataAdditional, rowsDataAdditionalTemp]);
            setRowsDataAdditionalTemp(intialRowDataAdditional)

        }
        else {
            //   Swal.fire({
            //     text: 'Please select the Person and their Additional from the dropdown above',
            //   })
        }
    }

    const deleteAdditionalTableRows = (index) => {
        const rows = [...rowsDataAdditional];
        rows.splice(index, 1);
        setRowsDataAdditional(rows);
    }

    useEffect(() => {
        console.log("get all users")
        getUsers();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmitEscalateCase} className='h-screen pt-4 pb-8 overflow-y-auto p-4 md:p-6' >
                <div className="justify-center mt-3 mb-4 md:mb-7">
                    <p className="text-xl md:text-2xl xl:text-2xl 3xl:text-2xl font-bold text-center ">
                        Person to whom the case will be escalated.
                    </p>
                    <div className="border-b-2 col-span-full my-3 md:my-6 2xl-10" />
                </div>
                <div className="grid grid-cols-12 gap-1 md:gap-4 p-2 md:p-4">
                    <div className="md:col-span-6 col-span-full">
                        <label
                            htmlFor="description"
                            className="block text-[1.1rem] 3xl:text-[1.5rem] my-2 3xl:my-4 font-semibold text-gray-600"
                        >
                            Escalate case to <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="mt-2 relative rounded-md shadow-sm">
                            <select
                                onChange={handleChangeEscalateForm}
                                // value={rowsDataClassificationTemp.name}
                                name='escalateTo'
                                className="sm:col-span-3 col-span-12 bg-gray-50 border border-gray-300 text-gray-900 text-[1rem] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                                <option value="" />
                                {people.length > 0 ? people.map((e, key) => {
                                    return (
                                        <option id={e._id} value={e._id}>
                                            {e.first_name} {e.last_name}
                                        </option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className="col-span-full flex flex-wrap">
                        <div className="w-[70%]">
                            <label
                                className="text-[1.1rem] my-2 font-semibold text-gray-600"
                            >
                                Are you escalating this case for safety reasons? <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="float-right max-w-[30%]">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="forSafety"
                                    id="yes_forSafety"
                                    value="Yes"
                                    onChange={handleChangeEscalateForm}
                                    hidden
                                />
                                <label
                                    htmlFor="yes_forSafety"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    Yes
                                </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="forSafety"
                                    id="no_forSafety"
                                    value="No"
                                    onChange={handleChangeEscalateForm}
                                    hidden
                                />
                                <label
                                    htmlFor="no_forSafety"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-full flex flex-wrap">
                        <div className="w-[70%]">
                            <label
                                className="text-[1.1rem] my-2 max-w-[70%] font-semibold text-gray-600"
                            >
                                Could the needs of the participants be met without the input of the party who no longer wants to participate? <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="float-right max-w-[70%]">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="noParticipants"
                                    id="yes_noParticipants"
                                    value="Yes"
                                    onChange={handleChangeEscalateForm}
                                    hidden
                                />
                                <label
                                    htmlFor="yes_noParticipants"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    Yes
                                </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="noParticipants"
                                    id="no_noParticipants"
                                    value="No"
                                    onChange={handleChangeEscalateForm}
                                    hidden
                                />
                                <label
                                    htmlFor="no_noParticipants"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-full flex flex-wrap">
                        <div className="w-[70%]">
                            <label
                                className="text-[1.1rem] my-2 font-semibold text-gray-600"
                            >
                                Would a larger meeting, including additional people, assist the process? <span style={{ color: "red" }}>*</span>
                            </label>
                        </div>
                        <div className="float-right max-w-[30%]">
                            <div className="bg-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="assistProcess"
                                    id="yes_assistProcess"
                                    value="Yes"
                                    onChange={handleChangeEscalateForm}
                                    hidden
                                />
                                <label
                                    htmlFor="yes_assistProcess"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    Yes
                                </label>
                            </div>
                            <div className="bg-black text-white border border-black inline-flex mx-4 rounded-lg">
                                <input
                                    type="radio"
                                    name="assistProcess"
                                    id="no_assistProcess"
                                    value="No"
                                    onChange={handleChangeEscalateForm}
                                    hidden
                                />
                                <label
                                    htmlFor="no_assistProcess"
                                    className="radio text-center self-center py-2 px-4 rounded-lg cursor-pointer hover:opacity-75"
                                >
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* <div className="grid grid-cols-12 gap-2 md:gap-5 my-2"> */}
                    <div className="sm:col-span-12 md:col-span-4 col-span-full">
                        <label
                            htmlFor="firstName"
                            className="block text-[1.1rem] font-semibold text-gray-600"
                        >
                            First Name
                        </label>
                        <div className="mt-2 relative rounded-md shadow-sm">
                            <input onChange={handleChangeAdditionalDataforTable}
                                type="text"
                                name="firstName"
                                id="firstName"
                                className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                            />
                        </div>
                    </div>
                    <div className="md:col-span-4 col-span-full">
                        <label
                            htmlFor="lastName"
                            className="block text-[1.1rem] font-semibold text-gray-600"
                        >
                            Surname
                        </label>
                        <div className="mt-2 relative rounded-md shadow-sm">
                            <input onChange={handleChangeAdditionalDataforTable}
                                type="text"
                                name="lastName"
                                id="lastName"
                                className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                            />
                        </div>
                    </div>
                    <div className="md:col-span-4 col-span-full">
                        <label
                            htmlFor="email"
                            className="block text-[1.1rem] font-semibold text-gray-600"
                        >
                            Email Address
                        </label>
                        <div className="mt-2 relative rounded-md shadow-sm">
                            <input onChange={handleChangeAdditionalDataforTable}
                                type="email"
                                name="email"
                                id="email"
                                className="text-gray-700 mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-[1.1rem]"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-full text-center mt-4">
                    {/* <p className="sm:col-span-6 col-span-full text-[1.1rem] font-medium my-2 text-center text-gray-600">
                      Do you need anything else from anyone else in order to move on?
                    </p> */}
                    <button
                        type="button"
                        onClick={addAdditionalTableRows}
                        className="inline-flex max-h-12 bottom-0 my-auto items-center px-6 py-2 border border-transparent text-[1.1rem] font-medium rounded-md shadow-md bg-green-600 hover:bg-green-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                        <span>Add</span>
                    </button>
                </div>
                {rowsDataAdditional.length > 0 ? (
                    <div className="mt-8">
                        <div className=" -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block py-2 align-middle md:px-6 lg:px-8 w-full">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                    <table className="min-w-full table-auto divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 w-[40%] text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Full Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 w-[50%] text-left text-[1rem] font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Email address
                                                </th>
                                                <th className="w-[10%]">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {rowsDataAdditional.map((e, key) => {
                                                return (<tr key={e.name}>
                                                    <td className="py-4 pl-4 pr-3 text-[0.95rem] sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="ml-2">
                                                                <div className="font-medium text-gray-900">
                                                                    {e.firstName + ' ' + e.lastName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="py-4 pl-4 pr-3 text-[0.95rem] sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="ml-2">
                                                                <div className="font-medium text-gray-900">
                                                                    {e.email}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="ml-2">
                                                                <div className="font-medium text-gray-900">
                                                                    <button className="inline-block align-middle select-none border font-normal text-xs whitespace-no-wrap rounded px-1 text-gray-800 border-gray-800 hover:bg-gray-800 hover:text-white bg-white" onClick={() => (deleteAdditionalTableRows(key))}>x</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''
                }
                {/* </div> */}
                <div className="flex justify-end mb-16">
                    <button
                        id="addPersonDetails"
                        type="submit"
                        className="px-5 py-2 m-2 bg-green-600 hover:bg-green-800 text-white cursor-pointer rounded-md"
                    >
                        Escalate
                    </button>
                    <button
                        onClick={popupEscalateCaseClose}
                        id="closePerson"
                        type="button"
                        className="px-5 py-2 m-2 bg-red-500 hover:bg-red-700 text-white cursor-pointer rounded-md"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </>
    )
};