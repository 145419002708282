import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React, { useState, useContext, useEffect } from "react";
import Loader from "./Loader";
import Swal from "sweetalert2";
import { addNewUserReq, updateUserReq } from "../api/user/user.service";
import { CurrentUserForReg } from '../pages/admin'


export default function Registration(props) {
  const [isLoading, setIsLoading] = useState(false);
  const currentUserForRegForm = useContext(CurrentUserForReg);
  const intialRegForm = { title: '', first_name: '', last_name: '', role: '', email: '', contact_number: '', active: true }
  const [regFormData, setRegFormData] = useState(intialRegForm)
  const [old, setOld] = useState();

  const setUserData = () => {
    if (currentUserForRegForm) {
      console.log("currentUserForRegForm")
      console.log(currentUserForRegForm)
      setRegFormData(currentUserForRegForm)
      setOld(true)
    } else {
      setRegFormData(intialRegForm)
      setOld(false)
    }
  }

  // first letter capital
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleChangeRegForm = (e) => {
    if (e.target.name == "first_name" || e.target.name == "last_name") {
      setRegFormData({
        ...regFormData,
        [e.target.name]: capitalizeFirstLetter(e.target.value)
      });
    }else if (e.target.name == "email") {
      console.log(e.target.name)
      setRegFormData({
          ...regFormData,
          [e.target.name]: e.target.value.toLowerCase()
      });
    } else {

      setRegFormData({
        ...regFormData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleActiveStatus = (e) => {
    console.log(e.target.checked)
    setRegFormData({
      ...regFormData,
      active: e.target.checked
    });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function userUpdate(userInfo) {
    try {
      console.log(userInfo);
      updateUserReq(userInfo)
        .then(function (response) {
          setIsLoading(false);
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: "User updated successfully",
              icon: "success",
            });
            props.onSubmit()
          }
          return response.data;
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.message) {
            Swal.fire({
              title: "Warning!",
              text: error.response.data.message,
              icon: "error",
            });
          }
        });
    } catch (e) {
      console.log(e)
      setIsLoading(false);
      Swal.fire({
        title: "Warning!",
        text: e.message,
        icon: "error",
      });
    }
  }

  function userRegistration(userInfo) {
    try {
      addNewUserReq(userInfo)
        .then(function (response) {
          setIsLoading(false);
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: "User added successfully",
              icon: "success",
            });
            props.onSubmit()
          }
          return response.data;
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error)
          if (error.message) {
            Swal.fire({
              title: "Warning!",
              text: error.response.data.message,
              icon: "error",
            });
          }
        });
    } catch (e) {
      console.log(e)

      setIsLoading(false);
      Swal.fire({
        title: "Warning!",
        text: e.message,
        icon: "error",
      });
    }
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (old) {
      userUpdate(regFormData);
    } else {
      userRegistration(regFormData);
    }
    props.onSubmit()

    setIsLoading(false);
  };

  useEffect(() => {
    setUserData()
  }, [])

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            props.onClose();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                  <form onSubmit={handleSubmit} method="POST">
                    <div>
                      <div className="overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="mt-3 sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-lg text-center leading-6 font-bold text-gray-800"
                            >
                              User details
                            </Dialog.Title>
                            <div className="border-b-2 col-span-full my-3 md:my-6" />
                            {old ? <div className="text-left items-center mb-6">
                              <label htmlFor="default-toggle" className="inline-flex relative items-center cursor-pointer">
                                <input type="checkbox" name="active" onChange={handleActiveStatus} id="default-toggle" className="sr-only peer" defaultChecked={regFormData.active ? true : false} />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                                <span className="ml-3 text-[1.1rem] font-medium text-gray-600">Active Status</span>
                              </label>
                            </div>
                              : ''}



                            <div className="grid grid-cols-6 gap-4">
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="title"
                                  className="block text-[1rem] font-semibold my-2 text-gray-600 "
                                >
                                  Title <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <select
                                    required
                                    name="title"
                                    id="title"
                                    onChange={handleChangeRegForm}
                                    value={regFormData.title}
                                    className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                                  >
                                    <option value=""></option>
                                    <option value="Mr">Mr</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Officer">Officer</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-span-6 sm:col-span-3"></div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-[1rem] font-semibold my-2 text-gray-600"
                                >
                                  First Name
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    required
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    onChange={handleChangeRegForm}
                                    value={regFormData.first_name}
                                    className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                                  />
                                </div>
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-[1rem] font-semibold my-2 text-gray-600"
                                >
                                  Surname
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    required
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    onChange={handleChangeRegForm}
                                    value={regFormData.last_name}
                                    className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                                  />
                                </div>
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="userTypeReg"
                                  className="block text-[1rem] font-semibold my-2 text-gray-600"
                                >
                                  User Type
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <select
                                    required
                                    name="role"
                                    id="role"
                                    onChange={handleChangeRegForm}
                                    value={regFormData.role}
                                    className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                                  >
                                    <option value=""></option>
                                    <option value="Administrator">
                                      Administrator
                                    </option>
                                    <option value="Practitioner">
                                      Practitioner
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-span-6 sm:col-span-4">
                                <label
                                  htmlFor="email"
                                  className="block text-[1rem] font-semibold my-2 text-gray-600"
                                >
                                  Email <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    required
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={handleChangeRegForm}
                                    value={regFormData.email}
                                    className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                                  />
                                </div>
                              </div>
                              <div className="col-span-6 sm:col-span-4">
                                <label
                                  htmlFor="teleReg"
                                  className="block text-[1rem] font-semibold my-2 text-gray-600"
                                >
                                  Telephone Number
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    type="tele"
                                    name="contact_number"
                                    id="contact_number"
                                    onChange={handleChangeRegForm}
                                    value={regFormData.contact_number}
                                    className="text-gray-700 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[1.1rem]"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 text-right">
                      <button
                        type="button"
                        className="inline-flex justify-center m-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-[1.1rem] font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:text-sm"
                        onClick={() => props.onClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="Submit"
                        className="inline-flex justify-center m-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-[1.1rem] font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
